import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Toptitle from "../components/toptitle"

const Facility = () => (
  <Layout>
    <SEO title="設備紹介" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Facility</Toptitle>
        <div className="pt-32 lg:mt-16 lg:pt-0">
          <h1 className="font-mincho text-3xl text-primary">CNC</h1>
        </div>
        <div>
          <YoutubeAsepect className="mt-4">
            <iframe
              src="https://www.youtube.com/embed/_xl-n9Ni3Jw?rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 h-full w-full"
            ></iframe>
          </YoutubeAsepect>
          <div className="mt-2 grid grid-cols-2 gap-2 lg:mt-4 lg:gap-4">
            <StaticImage src="../images/facility/cnc1.jpg" alt="CNCの全景1" />
            <StaticImage src="../images/facility/cnc2.jpg" alt="CNCの全景1" />
            <StaticImage src="../images/facility/cnc3.jpg" alt="CNCの全景1" />
          </div>
        </div>
        <div className="mt-16 lg:mt-32">
          <h2 className="font-mincho text-3xl text-primary">モルダー</h2>
        </div>
        <div className="mt-4">
          <YoutubeAsepect>
            <iframe
              src="https://www.youtube.com/embed/zDqTm3oNESQ?rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 h-full w-full"
            ></iframe>
          </YoutubeAsepect>
          <div className="mt-2 grid grid-cols-2 gap-2 lg:mt-4 lg:gap-4">
            <StaticImage
              src="../images/facility/molder1.jpg"
              alt="モルダーの全景1"
            />
            <StaticImage
              src="../images/facility/molder2.jpg"
              alt="モルダーの全景2"
            />
            <StaticImage
              src="../images/facility/molder3.jpg"
              alt="モルダーの全景3"
            />
          </div>
        </div>
        <div className="mt-16 lg:mt-32">
          <h2 className="font-mincho text-3xl text-primary">ランニングソー</h2>
        </div>
        <div className="mt-4">
          <YoutubeAsepect>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Xad33YHRkYI?rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 h-full w-full"
            ></iframe>
          </YoutubeAsepect>
          <div className="mt-2 grid grid-cols-2 gap-2 lg:mt-4 lg:gap-4">
            <StaticImage
              src="../images/facility/saw1.jpg"
              alt="ランニングソーの全景1"
            />
            <StaticImage
              src="../images/facility/saw2.jpg"
              alt="ランニングソーの全景2"
            />
            <StaticImage
              src="../images/facility/saw3.jpg"
              alt="ランニングソーの全景3"
            />
          </div>
        </div>
        <div className="mt-16 lg:mt-32">
          <h2 className="font-mincho text-3xl text-primary">工場全景</h2>
        </div>
        <div className="mt-4">
          <YoutubeAsepect>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/xPhof2hBeTE?rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 h-full w-full"
            ></iframe>
          </YoutubeAsepect>
        </div>
      </section>
    </div>
  </Layout>
)

const YoutubeAsepect = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
`

export default Facility
